import { Col, Row } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { formatDate } from '../../helpers/utils';
import PixReceiptButton from '../ReceiptButton';

import { BalanceHistoryInterfacePagination, BalanceHistoryInterfaceWithCodes } from '../../types/balance_history.type';

import {ReactComponent as CalendarIcon} from '../../static/icon/calendar-green.svg';
import {ReactComponent as TransactionTypeIcon} from '../../static/icon/transaction-type.svg';
import {ReactComponent as MoneyIcon} from '../../static/icon/money-green.svg';

import './styles.css'

interface PaymentVoucherTableProps {
  balance_history?: BalanceHistoryInterfaceWithCodes[],
  active_scroll?: boolean
  pagination?: BalanceHistoryInterfacePagination;
  changePage?: (page: number) => void;
  currentPage?: number;
}

const PaymentVoucherTable = ({ 
  balance_history,
  pagination,
  active_scroll,
  changePage,
  currentPage
}: PaymentVoucherTableProps) => {
  const columns: ColumnsType<BalanceHistoryInterfaceWithCodes> = [
    {
      key: 'date',
      dataIndex: 'date',
      render: (record: number) => {
        const date = new Date(record * 1000);
        return (
          <Col className='balance-table-cell first-cell'>
              <Row align='middle'>
                  <CalendarIcon />
                  <span className='balance-table-title'>Data da operação</span>
              </Row>
              <Row style={{width: 145}}>
                  <span className='balance-table-value'>{formatDate(date)}</span>
              </Row>
          </Col>
        )
      },
    },
    {
      key: 'value',
      dataIndex: 'value',
      render: (record:string) => {
        return (
          <Col className='balance-table-cell'>
              <Row align='middle'>
                  <MoneyIcon />
                  <span className='balance-table-title'>Valor</span>
              </Row>
              <Row>
                  <span className='balance-table-value'>{Number(record).toLocaleString("pt-br", {style: "currency", currency: "BRL"})}</span>
              </Row>
          </Col>
        )
      }
    },
    {
      key: 'operation_type',
      dataIndex: 'operation_type',
      render: (record:string) => {
        return (
            <Col className='balance-table-cell'>
                <Row align='middle'>
                    <TransactionTypeIcon />
                    <span className='balance-table-title'>Operação</span>
                </Row>
                <Row>
                    <span className='balance-table-value'>{record}</span>
                </Row>
            </Col>
        )
      }
    },
    {
      key: 'code',
      dataIndex: 'operation_type',
      render: (operation_type: string, record: BalanceHistoryInterfaceWithCodes) => {
        if (operation_type === 'PIX') {
          return (
            <Col className='balance-table-cell'>
                <Row align='middle'>
                    <span className='balance-table-title'>Código PIX</span>
                </Row>
                <Row>
                    <span className='balance-table-value'>{record.code}</span>
                </Row>
            </Col>
          )
        } else {
          return (
            <Col className='balance-table-cell'>
                <Row align='middle'>
                    <span className='balance-table-title'>Código do Boleto</span>
                </Row>
                <Row>
                    <span className='balance-table-value'>{record.code}</span>
                </Row>
            </Col>
          )
        }
      }
    },
    {
      key: "actions",
      dataIndex: "withdrawal_id",
      width: 115,
      render: (record:number) => {
        return (
          <Col className='balance-table-cell last-cell'>
            <PixReceiptButton withdraw_id={record} theme="dark"/>
          </Col>
        )
      }
    }
  ];

  return (
    <div>
      <Table
        rowKey='id'
        size="middle"
        className="balance-table"
        showHeader={false}
        columns={columns} 
        scroll={active_scroll ? { x: 1000, y: 600 } : undefined}
        dataSource={balance_history}
        pagination={!changePage ? false : {
          position: ['bottomCenter'],
          total: pagination ? pagination.pages * pagination.per_page : 0,
          showSizeChanger: false,
          current: currentPage,
          onChange(page) {
            changePage(page)
          },
        }}
      />
    </div>
  );
};

export default PaymentVoucherTable;
