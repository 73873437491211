import { ConnectedProps, connect } from "react-redux";

import { companiesActions } from "../../../actions/companies";
import PageHeader from "../../../components/Layout/PageHeader";

import { BalanceHistoryInterfacePagedWithCodes } from "../../../types/balance_history.type";
import { UserInterface } from "../../../types/user.type";
import { useEffect, useState } from "react";
import PaymentVoucherTable from "../../../components/PaymentsVoucherTable";

import './styles.css'
import InputText from "../../../components/Inputs/Text";
import { Button } from "antd";
import {ReactComponent as SearchIcon} from '../../../static/icon/search.svg';
import { CloseOutlined } from '@ant-design/icons';


interface RootState {
    balance_history: {
        certificates: BalanceHistoryInterfacePagedWithCodes;
    };
    
    authentication: {
        user: UserInterface;
    }
}

const mapState = (state: RootState) => ({
    certificates: state.balance_history.certificates,
    user: state.authentication.user,
});

const actionCreators = {
    listPixCertificatesPaged: companiesActions.listPixCertificatesPaged,
    listTicketsCertificatesPaged: companiesActions.listTicketsCertificatesPaged,
    listCertificatesByCodePaged: companiesActions.listCertificatesByCodePaged
};

const connector = connect(mapState, actionCreators);

type PropsFromRedux = ConnectedProps<typeof connector>;

const PaymentVoucher = ({ 
    user,
    certificates, 
    listPixCertificatesPaged,
    listTicketsCertificatesPaged,
    listCertificatesByCodePaged
}: PropsFromRedux) => {

    const storage = localStorage.getItem("userDetails")
    const userDetails:UserInterface = storage && JSON.parse(storage)
    //const operationsName = userDetails.limits?.map(item => item.operation_type_name)
    const operationsId = userDetails.limits?.map(item => item.operation_type_id)
    const [filterOperation, setFilterOperation] = useState<string>("")
    const [codeFilter, setCodeFilter] = useState<string>("")
    const [currentPage, setCurrentPage] = useState(1);
    const [codeFilterType, setCodeFilterType] = useState<boolean>(false)

    const getPixBalanceHistory = (page: number) => {
        if(user && user.companies && user.companies.length > 0) {
            const company_id = user.companies[0].id
            listPixCertificatesPaged(company_id, page);
        }
    }

    const getTicketsBalanceHistory = (page: number) => {
        if(user && user.companies && user.companies.length > 0) {
            const company_id = user.companies[0].id
            listTicketsCertificatesPaged(company_id, page);
        }
    }

    const getBalanceHistoryCertificatesByCode = (page: number, codeFilter:string) => {
        if(user && user.companies && user.companies.length > 0) {
            const company_id = user.companies[0].id
            listCertificatesByCodePaged(company_id, page, codeFilter);
        }
    }

    useEffect(() => {
        if (user.role === "admin") {
            console.log("opa")
        } else if(user && user.companies && user.companies.length > 0) {
            if (codeFilterType){
                getBalanceHistoryCertificatesByCode(1, codeFilter)
            }else{
                if (filterOperation === "pix") {
                    getPixBalanceHistory(1)
                }else{
                    getTicketsBalanceHistory(1);
                }
            }
        }
    }, [filterOperation]);

    const handleFilterPayementVoucher = () => {
        console.log(certificates.data)
        if (certificates.data) {
            const paymentVoucher = certificates.data//.filter(item => item.withdrawal_id > 0)
    
            return paymentVoucher
        }
    }

    const changePage = (page: number) => {
        setCurrentPage(page);
        if (codeFilterType){
            getBalanceHistoryCertificatesByCode(page, codeFilter)
        }else{
            if (filterOperation === "pix") {
                getPixBalanceHistory(page)
            }else{
                getTicketsBalanceHistory(page);
            }
        }
    }

    const handleFilterOperationClick = (operation: string) => {
        setFilterOperation(operation);
        changePage(1);
    }

    return (
        <>
            <PageHeader title="Comprovantes"/>
            <div className="voucher-container">
                <div className="voucher-filter-content"> 
                    <div className="voucher-filter-content-button">
                        {operationsId?.includes(6) &&                                
                            <button 
                                className={`voucher-filter-button ${filterOperation === "boleto" && 'voucher-filter-button-active'}`} 
                                onClick={() => {
                                    setCodeFilter("");
                                    setCodeFilterType(false);
                                    handleFilterOperationClick("boleto");
                                }}>
                                Boleto
                            </button>
                        }
                        {operationsId?.includes(8) &&
                            <button 
                                className={`voucher-filter-button ${filterOperation === "pix" && 'voucher-filter-button-active'}`} 
                                onClick={() => {
                                    setCodeFilter("");
                                    setCodeFilterType(false);
                                    handleFilterOperationClick("pix");
                                }}>
                                PIX
                            </button>
                        }
                    </div>
                    <div className="certificates-filter-content">
                        <InputText 
                            kind="primary"
                            placeholder="Digite o código pix ou código de barras do boleto"
                            value={codeFilter}
                            disabled={codeFilterType}
                            suffix={codeFilter === "" ? !codeFilterType : (
                                <Button 
                                    shape="circle" 
                                    size="large" 
                                    icon={<CloseOutlined />}
                                    className="button-input"
                                    onClick={() => {
                                        setCodeFilterType(false);
                                        setCodeFilter("");
                                        handleFilterOperationClick("boleto");        
                                    }}
                                />
                            )}
                            onChange={(event) => {
                                //if (!codeFilterType)
                                    const value = event.target.value
                                    const sanitizedValue = value.replace(/[\/\s]/g, "");
                                    setCodeFilter(sanitizedValue);
                                }
                            }
                        />
                        <Button 
                            shape="circle" 
                            size="large" 
                            icon={<SearchIcon/>}
                            disabled={true ? codeFilterType : false}
                            onClick={() => {
                                setCodeFilterType(true);
                                getBalanceHistoryCertificatesByCode(1, codeFilter);}}
                        />
                    </div>  
                </div>
                <div>
                    {certificates &&
                        <PaymentVoucherTable 
                            balance_history={handleFilterPayementVoucher()}
                            pagination={certificates.pagination}
                            changePage={changePage}
                            currentPage={currentPage}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default connector(PaymentVoucher);